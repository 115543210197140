<template>
  <div class="page-wrapper">
    <Navbar />
    <div class="container-fluid">
      <div class="row">
        <div class="col-5 px-0 mx-auto">

          <div class="checkout-wrapper">
            
            <Loader v-if="isLoading" loading :title="$t('Fetching pricing...')" />
            <component 
              :is="`${step}-section`" 
              v-else 
              :ref="'section'" 
              :products="[]" 
              :current-props="currentProps" 
              :quote-code="quoteCode"
              pay-only 
              @settings="onReceiveSettings"
              @changeStep="onChangeStep"
            />
            
            <div class="row checkout-wrapper-footer">
              <div class="col">
                <SupportBlock minified />
              </div>
              <div v-if="!isLoading" class="col-auto">
                <button class="btn btn-outline-primary px-5 ml-auto" :class="{ ['mr-2']: primary }" @click="onClickSecondary">{{secondary}}</button>
                <button v-if="primary" class="btn btn-primary px-5 ml-auto" @click="onClickPrimary">{{primary}}</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    
    <vue-headful v-if="order" :title="$t(`${order.order_number} - FileCloud Customer Portal`)"/>
    <vue-headful v-else :title="$t(`Quote - FileCloud Customer Portal`)"/>
    
  </div>
</template>

<script>

import Logo from '@/assets/logo.svg';
import Navbar from '@/components/Partials/Navbar.vue';
import SupportBlock from '@/components/Partials/SupportBlock.vue';
import Icon from 'common/components/Icon.vue';
import Loader from 'common/components/Loader.vue';

import SummarySection from '@/components/Checkout/Summary.vue';
import PaymentSection from '@/components/Checkout/Payment.vue';
import PaidSection from '@/components/Checkout/Paid.vue';

export default {
  name: 'Dashboard',
  components: {
    Logo,
    Navbar,
    Icon,
    Loader,
    SummarySection,
    PaymentSection,
    PaidSection,
    SupportBlock
  },
  data() {
    return {
      currentStep: 'summary',
      primary: '',
      secondary: '',
      currentProps: {
        orderNumber: 'public'
      }
    }
  },
  computed:{
    user(){
      return this.$store.state.core.user;
    },
    step() {
      
      if(this.currentStep === 'pricing' || this.currentStep === 'details') {        
        return `${this.currentStep}-${this.currentProps.group}`;
      } else if (this.currentStep) {
        return this.currentStep;
      }
      
      return 'welcome';
    },
    order() {
      return this.$store.state.checkout.singleOrder.public;
    },
    posts() {
      return this.$store.state.core.blogPosts.slice(0, 4);
    },
    isLoading() {
      return false;
    },
    quoteCode() {
      return this.$route.params.code;
    }
  },
  mounted() {
    // this.$store.dispatch('checkout/getPublicQuote', this.$route.params.code);
  },
  methods: {
    onReceiveSettings(data) {
      this.primary = data.primary;
      this.secondary = data.secondary;
    },
    onChangeStep({ step, props }) {
      this.currentProps = { ...this.currentProps, previousStep: this.currentStep, ...props };
      this.currentStep = step;
    },
    onClickPrimary() {
      this.$refs.section.onClickPrimary();
    },
    onClickSecondary() {
      this.$refs.section.onClickSecondary();
    }
  }
}
</script>
